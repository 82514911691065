import { generatePath } from 'react-router';

const urls = {
  login: { index: '/login' },

  signup: { index: '/signup' },

  completedSetup: { index: '/complete-setup' },

  forgotPassword: {
    index: '/forgot-password',
    sent: '/forgot-password/sent',
    reset: '/reset-password',
  },

  home: {
    index: '/',
  },

  dashboard: {
    index: '/dashboard',
  },

  business: {
    index: '/business',
    wallet: {
      index: '/wallet',
    },
    apps: {
      index: '/app',
      details: '/app/:id',
      for: (id: string) => generatePath('/app/:id', { id }),
    },
    decision_model: {
      index: '/decision-model',
      details: '/decision-model/:id',
      for: (id: string) => generatePath('/decision-model/:id', { id }),
    },
    audits_trails: {
      index: '/audit-trail',
      events: {
        index: '/audit-trail/event',
        details: '/audit-trail/event/:id',
        for: (id: string) => generatePath('/audit-trail/event/:id', { id }),
      },
      audit: {
        index: '/audit-trail/audit',
        details: '/audit-trail/audit/:id',
        for: (id: string) => generatePath('/audit-trail/audit/:id', { id }),
      },
    },
    report: {
      index: '/reports',
      details: '/reports/:id',
      for: (id: string) => generatePath('/reports/:id', { id }),
    },
  },

  settings: {
    index: '/settings',
    profile: {
      index: '/settings/profile',
    },
    account_management: {
      index: '/settings/account-management',
    },
    team_management: {
      index: '/settings/team-management',
      details: {
        index: '/settings/team-management/:id',
        for: (id: string) =>
          generatePath('/settings/team-management/:id', { id }),
      },
    },
    role_management: {
      index: '/settings/role-management',
      details: {
        index: '/settings/role-management/:id',
        for: (id: string) =>
          generatePath('/settings/role-management/:id', { id }),
      },
    },
    api_pricing: {
      index: '/settings/api-pricing',
      details: {
        index: '/settings/api-pricing/id',
        for: (id: string) => generatePath('/settings/api-pricing/:id', { id }),
      },
    },
    notification: {
      index: '/settings/notification',
    },
  },
};

export default urls;

export const externalRoutes = {
  docs: {
    index: 'https://docs.adjutor.io/',
    api_reference: 'https://api.adjutor.io/',
    pricing: 'https://docs.adjutor.io/authentication/pricing',
  },
  adjutor: {
    index: 'https://www.adjutor.io/',
    support: 'mailto:support@lendsqr.com',
  },
  faq: {
    index: 'https://www.adjutor.io/faq',
  },
  privacy_policy: {
    index: 'https://adjutor.io/legal/privacy',
  },
  terms_condition: {
    index: 'https://adjutor.io/legal/terms-of-use',
  },
  pecunia: {
    signin: 'https://app.lendsqr.com/',
  },
  lendsqr: {
    projectX: 'https://lendsqr.com/lenders/all-lenders',
  },
};
