import React from 'react';
import styles from './_report.module.scss';

import { Link } from 'react-router-dom';
import Card from '../../../components/common/cards/cards';
import FillButton from '../../../components/common/buttons/button';

export interface ReportCardProps {
  title: string;
  description: string;
  button: { label: string; link: string };
}

function ReportCard({ title, description, button }: ReportCardProps) {
  return (
    <Card className={styles.report_card}>
      <div className={styles.report_card_content}>
        <div className={styles.report_card_info}>
          <p className={styles.report_card_title}>{title}</p>
          <p className={styles.report_card_description}>{description}</p>
        </div>
        <div className={styles.report_card_button}>
          <Link to={button.link} className={styles.report_card_link}>
            <FillButton label={button.label} variant="ghost" />
          </Link>
        </div>
      </div>
    </Card>
  );
}

export default ReportCard;
