import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/customHooks';
import PageTitle from '../../../../components/common/page-title';
import { getMetabaseReport } from '../../../../store/reports/report.actions';
import { useParams } from 'react-router-dom';
import PageLoading from '../../../../components/page-loading/pageLoading';
import { IMetabaseReportState } from '../../../../store/reports/report.interface';

function ReportDetailsPage() {
  const dispatch = useAppDispatch();
  const params: any = useParams();

  const { metabaseReport, metabaseReports, isLoading, error } = useAppSelector(
    (state) => state.reports
  );

  const report = metabaseReports.find(
    (report: IMetabaseReportState) => String(report.id) === String(params.id)
  );

  useEffect(() => {
    if (params.id) {
      dispatch(getMetabaseReport(params.id));
    }
  }, [dispatch, params.id]);

  const hasReportData = metabaseReport && metabaseReport.length > 0;

  return (
    <div>
      {hasReportData && report && (
        <PageTitle title={report?.name} description={report?.description} />
      )}

      {isLoading && <PageLoading loadingMessage="Loading Report" />}

      {error && <p>Error loading report</p>}

      {!isLoading && !error && !hasReportData && <p>No report available.</p>}

      {!isLoading && !error && hasReportData && (
        <iframe
          src={metabaseReport}
          title="Metabase Report"
          width="100%"
          height="600px"
          style={{ border: 'none' }}
        />
      )}
    </div>
  );
}

export default ReportDetailsPage;
