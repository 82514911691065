import { createSlice } from '@reduxjs/toolkit';
import { getMetabaseReports, getMetabaseReport } from './report.actions';
import { IMetabaseReportsState } from './report.interface';

const initialState: IMetabaseReportsState = {
  metabaseReports: [],
  metabaseReport: null,
  isLoading: false,
  error: '',
};

const MetabaseReportsSlice = createSlice({
  name: 'ReportState',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch All Metabase Reports
    builder.addCase(getMetabaseReports.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getMetabaseReports.fulfilled, (state, action) => {
      state.metabaseReports = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getMetabaseReports.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });

    // Fetch Single Metabase Report
    builder.addCase(getMetabaseReport.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getMetabaseReport.fulfilled, (state, action) => {
      state.metabaseReport = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getMetabaseReport.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
});

export default MetabaseReportsSlice.reducer;
