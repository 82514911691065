import React, { useEffect, useState, useMemo } from 'react';
import './_app-details.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/customHooks';
import urls from '../../../../routes/all-routes';

import FallbackLogo from '../../../../assets/illustrations/fallback-image.png';
import { ReactComponent as ResetIcon } from '../../../../assets/icons/refresh.svg';
import { ReactComponent as MoreIcon } from '../../../../assets/icons/more.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/trash.svg';
import {
  deleteAppAction,
  getSingleAppAction,
  getSingleAppLogsAction,
} from '../../../../store/apps/apps.actions';
import { IAppsState } from '../../../../store/apps/apps.interface';
import FillButton, {
  ButtonColor,
} from '../../../../components/common/buttons/button';
import Card from '../../../../components/common/cards/cards';
import PageLoading from '../../../../components/page-loading/pageLoading';
import Modal, {
  ModalPosition,
} from '../../../../components/common/modal/modal';
import CreateAppPane from '../create-app-pane';
import PermissionsGate from '../../../../utils/hooks/permission-gate';
import { ADJUTOR_SCOPES } from '../../../../utils/hooks/permission-maps';
import { resetAppState } from '../../../../store/apps/apps.reducer';
import ModalFooter from '../../../../components/common/modal/modal-footer';
import PageTitle from '../../../../components/common/page-title';
import CardHeader from '../../../../components/common/cards/card-header';
import CardBody from '../../../../components/common/cards/card-body';
import IconButton from '../../../../components/common/buttons/icon-button';
import DropdownMenu from '../../../../components/common/dropdown/dropdown-menu';
import DropdownOption from '../../../../components/common/dropdown/dropdown-option';
import ResetAppTokenModal from './reset-app-token-modal';
import Select from '../../../../components/input-fields/Select/select';
import DashboardAnalytics from '../../../../shared/dashboard-analytics';
import CustomTabs from '../../../../components/tabls';
import AppDetails from './app-details';
import AppRequestsLogs from './app-request-logs';
import { CalculateDashboardAnalytics } from '../../../../hooks/useCalculateDashboardAnalytics';

function AppDetailsPage() {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResetTokenModal, setShowResetTokenModal] = useState(false);
  const [showEditAppPane, setShowEditAppPane] = useState(false);
  const dispatch = useAppDispatch();
  const params: any = useParams();
  const appState: IAppsState = useAppSelector((state) => state.apps);
  const focusApp = appState?.focusApp;

  useEffect(() => {
    dispatch(getSingleAppAction(params.id));
  }, []);

  const dateFilterOptions = [
    { label: 'Last 24 hours', value: 0 },
    { label: 'Last 7 days', value: 7 },
    { label: 'Last 30 days', value: 30 },
  ];

  const [dataFilter, setDataFilter] = useState<(typeof dateFilterOptions)[0]>(
    dateFilterOptions[0]
  );

  useMemo(() => {
    if (appState.deleteAppStatus === 'success') {
      navigate(urls.business.apps.index);
    }
  }, [appState.deleteAppStatus]);

  useMemo(() => {
    if (appState.appTokenStatus === 'reset.success') {
      dispatch(resetAppState());
    }
  }, [showResetTokenModal]);

  const [rawAnalyticsLogs, setRawAnalyticsLogs] = useState([]);

  useMemo(() => {
    dispatch(
      getSingleAppLogsAction({
        app_id: params.id,
        page: 1,
        limit: 10000,
      })
    )
      .unwrap()
      .then((data) => {
        setRawAnalyticsLogs(data.calls);
      });
  }, []);

  const { AveResponseTime, TotalCallMade, TotalCallsWithError } =
    CalculateDashboardAnalytics(rawAnalyticsLogs, dataFilter.value);

  return (
    <div className="app-details-page">
      <div className="page-header">
        <div className="title-row">
          <div className="logo-container">
            <img src={focusApp?.logo_url ?? FallbackLogo} alt="" />
          </div>

          <PageTitle
            title={`App/${focusApp?.name}`}
            description={
              <div className="app-id">{focusApp?.description ?? ''}</div>
            }
            rightElement={
              <div className="button-row">
                <PermissionsGate scopes={[ADJUTOR_SCOPES.canEditApps]}>
                  <FillButton
                    onClick={() => {
                      setShowEditAppPane(!showEditAppPane);
                    }}
                    label="Edit"
                  />
                </PermissionsGate>

                <PermissionsGate scopes={[ADJUTOR_SCOPES.canDeleteApps]}>
                  <DropdownMenu
                    renderButton={
                      <IconButton variant="outlined" icon={<MoreIcon />} />
                    }
                    className="action-dropdown"
                    id={`dropdown-menu-${32}`}
                  >
                    <PermissionsGate scopes={[ADJUTOR_SCOPES.canEditApps]}>
                      <DropdownOption
                        onClick={() => {
                          setShowResetTokenModal(!showResetTokenModal);
                        }}
                        icon={<ResetIcon />}
                        label="Reset API key"
                      />
                    </PermissionsGate>

                    <PermissionsGate scopes={[ADJUTOR_SCOPES.canDeleteApps]}>
                      <DropdownOption
                        onClick={() => {
                          setShowDeleteModal(!showDeleteModal);
                        }}
                        className="delete-app-button"
                        icon={<DeleteIcon />}
                        label="Delete app"
                      />
                    </PermissionsGate>
                  </DropdownMenu>
                </PermissionsGate>
              </div>
            }
          />
        </div>
      </div>

      {appState.isSingleAppLoading || appState.appTokenStatus === 'loading' ? (
        <PageLoading loadingMessage="Loading App" />
      ) : (
        <>
          <div className="page-header">
            <Select
              value={dataFilter}
              selectOptions={dateFilterOptions}
              onChange={(val: any) => {
                setDataFilter(val);
              }}
            />
          </div>

          <DashboardAnalytics
            totalAveResTime={AveResponseTime}
            totalCall={TotalCallMade?.length}
            totalError={TotalCallsWithError?.length}
            dataFilter={dataFilter}
            allApiCalls={TotalCallMade}
            averageLatency={'0'}
            className="app_analytics_dashboard"
          />

          <CustomTabs
            tabs={[
              {
                title: 'App Details',
                route: '',
                content: (
                  <Card
                    className="details-card"
                    cardHeading={<CardHeader title="App Details" />}
                  >
                    <CardBody>
                      <AppDetails focusApp={focusApp} />
                    </CardBody>
                  </Card>
                ),
              },
              {
                title: 'API Logs',
                route: '',
                content: <AppRequestsLogs app_id={Number(focusApp.id)} />,
                permissionScope: [ADJUTOR_SCOPES.canViewAudit],
              },
            ]}
          />
        </>
      )}

      <Modal
        isOpen={showDeleteModal}
        title="Confirm action"
        onClose={() => {
          setShowDeleteModal(!showDeleteModal);
        }}
        className="delete-app-modal"
        modalPosition={ModalPosition.center}
        modalFooter={
          <ModalFooter>
            <FillButton
              onClick={() => {
                setShowDeleteModal(!showDeleteModal);
              }}
              colorVariant={ButtonColor.neutral}
              variant="outlined"
              className="close-button"
              disabled={appState.deleteAppStatus === 'loading'}
              label="Cancel"
            />

            <FillButton
              onClick={() => {
                dispatch(deleteAppAction(params.id));
              }}
              colorVariant={ButtonColor.danger}
              isLoading={appState.deleteAppStatus === 'loading'}
              label="Yes, Delete"
            />
          </ModalFooter>
        }
      >
        <p>
          Are you sure you want to delete <b>{appState.focusApp.name}</b>? This
          action is irreversible and all app credentials will become invalid
          afterwards.
        </p>
      </Modal>

      <ResetAppTokenModal
        isOpen={showResetTokenModal}
        handleClose={() => {
          setShowResetTokenModal(!showResetTokenModal);
        }}
      />

      <CreateAppPane
        isOpen={showEditAppPane}
        onClose={() => {
          setShowEditAppPane(!showEditAppPane);
        }}
        title="Edit app"
        type="edit"
        initialValues={{
          name: focusApp.name,
          description: focusApp.description ?? '',
          webhook_url: focusApp.webhook_url ?? '',
          callback_url: focusApp.callback_url ?? '',
          services: focusApp.services,
          logo_url: focusApp.logo_url ?? '',
        }}
      />
    </div>
  );
}

export default AppDetailsPage;
