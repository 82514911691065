import React from 'react';

//authentication
import LoginPage from '../pages/auth/login/login';
import ForgotPasswordPage from '../pages/auth/forgot-password/forgot-password';
import ForgotPasswordEmailSentPage from '../pages/auth/forgot-password/forgot-password-mail-sent';
import SignUpPage from '../pages/auth/sign-up/sign-up';
import CompleteSetupPage from '../pages/auth/onboarding/onboarding';

//Home and Dashboard
import HomePage from '../pages/home/home';
import DashboardPage from '../pages/dashboard/dashboard';

//Business
import WalletPage from '../pages/business/wallet';
import AppPage from '../pages/business/apps';
import DecisionModelManagePage from '../pages/business/decision model/decision-model-manage';

//settings
import SettingsPage from '../pages/settings';

//404
import NotFoundPage from '../pages/404/404';
import EventDetailsPage from '../pages/business/audit-trail/Events/event-details/event-details';
import urls from './all-routes';
import AuditDetailsPage from '../pages/business/audit-trail/Api-logs/audit-details/audit-details';
import { redirect } from 'react-router-dom';
import AppDetailsPage from '../pages/business/apps/app-details';
import { ADJUTOR_SCOPES } from '../utils/hooks/permission-maps';
import DecisionModelListPage from '../pages/business/Models';
import ResetPasswordPage from '../pages/auth/forgot-password/reset-password';
import AuditTrailPage from '../pages/business/audit-trail';
import ReportPage from '../pages/business/reports';
import ReportDetailsPage from '../pages/business/reports/report-details';

interface RouterInterface {
  path: string;
  element: React.ReactElement;
  action?: () => void;
  loader?: () => void;
}
interface ProtectedRoutesInterface extends RouterInterface {
  children?: RouterInterface[];
  scopes?: string[];
  breadcrumb?: {
    path: string;
    title: string;
  }[];
}

const protectedRoutes: ProtectedRoutesInterface[] = [
  //Business
  {
    path: urls.business.wallet.index,
    element: <WalletPage />,
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.business.wallet.index, title: 'Wallets' },
    ],
  },
  {
    path: urls.business.apps.index,
    element: <AppPage />,
    scopes: [ADJUTOR_SCOPES.canViewApps, ADJUTOR_SCOPES.canCreateApps],
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.business.apps.index, title: 'Apps' },
    ],
  },
  {
    path: urls.business.apps.details,
    element: <AppDetailsPage />,
    scopes: [
      ADJUTOR_SCOPES.canDeleteApps,
      ADJUTOR_SCOPES.canViewApps,
      ADJUTOR_SCOPES.canEditApps,
    ],
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.business.apps.index, title: 'Apps' },
      { path: urls.business.apps.details, title: 'Apps' },
    ],
  },
  {
    path: urls.business.audits_trails.events.details,
    element: <EventDetailsPage />,
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.business.audits_trails.index, title: 'Audit Logs' },
      {
        path: urls.business.audits_trails.events.index,
        title: 'Activity Logs',
      },
      {
        path: urls.business.audits_trails.events.details,
        title: 'Activity Logs',
      },
    ],
  },
  {
    path: urls.business.audits_trails.audit.details,
    element: <AuditDetailsPage />,
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.business.audits_trails.index, title: 'Audit Logs' },
      { path: urls.business.audits_trails.audit.index, title: 'API Logs' },
      { path: urls.business.audits_trails.audit.details, title: 'API Logs' },
    ],
  },
  {
    path: urls.business.audits_trails.index,
    element: <AuditTrailPage />,
    loader: () => {
      return redirect(urls.business.audits_trails.audit.index);
    },
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.business.audits_trails.index, title: 'Audit Logs' },
    ],
  },
  {
    path: `${urls.business.audits_trails.index}/*`,
    element: <AuditTrailPage />,
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.business.audits_trails.index, title: 'Audit log' },
    ],
  },

  //Decsion Model

  {
    path: urls.business.decision_model.index,
    element: <DecisionModelListPage />,
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.business.decision_model.index, title: 'Decision Models' },
    ],
  },
  {
    path: urls.business.decision_model.details,
    element: <DecisionModelManagePage />,
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.business.decision_model.index, title: 'Decision Models' },
      { path: urls.business.decision_model.details, title: 'Decision Models' },
    ],
  },

  //Reports
  {
    path: urls.business.report.index,
    element: <ReportPage />,
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.business.report.index, title: 'Reports' },
    ],
  },

  {
    path: urls.business.report.details,
    element: <ReportDetailsPage />,
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.business.report.index, title: 'Reports' },
      { path: urls.business.report.details, title: 'Reports' },
    ],
  },

  //Settings
  {
    path: `${urls.settings.index}/*`,
    element: <SettingsPage />,
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.settings.index, title: 'Settings' },
    ],
  },
  {
    path: urls.settings.index,
    element: <SettingsPage />,
    loader: () => {
      return redirect(urls.settings.account_management.index);
    },
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.settings.index, title: 'Settings' },
    ],
  },

  //Home
  {
    path: urls.home.index,
    element: <HomePage />,
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.home.index, title: 'Get Started' },
    ],
  },
  {
    path: urls.dashboard.index,
    element: <DashboardPage />,
    breadcrumb: [
      { path: urls.home.index, title: 'Home' },
      { path: urls.dashboard.index, title: 'Dashboard' },
    ],
  },
];

const unProtectedRoutes: ProtectedRoutesInterface[] = [
  //Auth
  { path: urls.login.index, element: <LoginPage /> },
  { path: urls.forgotPassword.index, element: <ForgotPasswordPage /> },
  { path: urls.forgotPassword.sent, element: <ForgotPasswordEmailSentPage /> },
  { path: urls.forgotPassword.reset, element: <ResetPasswordPage /> },
  { path: urls.signup.index, element: <SignUpPage /> },
  { path: urls.completedSetup.index, element: <CompleteSetupPage /> },
  { path: '*', element: <NotFoundPage /> },
];

export { protectedRoutes, unProtectedRoutes };
