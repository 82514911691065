import React from 'react';
import './_switch.scss';

export interface SwitchProps {
  isChecked: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const Switch = ({
  isChecked,
  setIsChecked,
  onChange,
  disabled = false,
}: SwitchProps) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          } else setIsChecked((prev) => !prev);
        }}
        disabled={disabled}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default Switch;
