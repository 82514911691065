import React, { useEffect } from 'react';
import styles from './_report.module.scss';

import { useAppDispatch, useAppSelector } from '../../../store/customHooks';
import { getMetabaseReports } from '../../../store/reports/report.actions';

import PageTitle from '../../../components/common/page-title';
import ReportCard from './reports-card';
import PageLoading from '../../../components/page-loading/pageLoading';

function ReportPage() {
  const dispatch = useAppDispatch();

  // Access reports from the Redux store
  const { metabaseReports, isLoading, error } = useAppSelector(
    (state) => state.reports
  );

  // Fetch reports data
  useEffect(() => {
    dispatch(getMetabaseReports());
  }, []);

  return (
    <div>
      <PageTitle
        title="Report"
        description="Get real-time reports for all your transactions."
      />

      <div className={styles.report_container}>
        <div className={styles.page_loading}>
          {isLoading && <PageLoading loadingMessage="Getting Reports" />}
        </div>

        {error && <p>Error loading reports: {error}</p>}
        {!isLoading && !error && metabaseReports?.length === 0 && (
          <p>No reports available.</p>
        )}
        {!isLoading &&
          !error &&
          metabaseReports?.length > 0 &&
          metabaseReports.map(
            (
              report: {
                id: number;
                name: string;
                description: string;
                link_text?: string;
              },
              index: number
            ) => (
              <ReportCard
                key={index}
                title={report.name}
                description={report.description}
                button={{
                  label: report.link_text || 'View Report',
                  link: `${report.id}`,
                }}
              />
            )
          )}
      </div>
    </div>
  );
}

export default ReportPage;
