import { createAsyncThunk } from '@reduxjs/toolkit';
import { callApi } from '../../config/apiSetup';
import {
  getMetabaseReportsUrl,
  getMetabaseReportUrl,
} from '../../config/allUrl';
import { toast } from 'react-hot-toast';
import { defaultErrorMessage } from '../../utils/helper';

export const getMetabaseReports = createAsyncThunk(
  'business/getMetabaseReports',
  async (dispatch, ThunkApi) => {
    try {
      const res = await callApi.get(getMetabaseReportsUrl);
      return res.data;
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);

export const getMetabaseReport = createAsyncThunk(
  'business/getMetabaseReport',
  async (id: number, ThunkApi) => {
    try {
      const res = await callApi.get(getMetabaseReportUrl(id));
      return res.data;
    } catch (error: any) {
      const message: string = error.response.data?.message || error?.message;
      toast.error(message ?? defaultErrorMessage);
      return ThunkApi.rejectWithValue(message);
    }
  }
);
