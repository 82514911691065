import React, { useEffect, useState } from 'react';
import './_topbar.scss';
import { useAppSelector, useAppDispatch } from '../../../store/customHooks';
import {
  OpenSideBar,
  CloseSideBar,
} from '../../../store/layout/layout.reducer';

//icons
import { ReactComponent as Menu } from '../../../assets/icons/menu.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrowhead-down.svg';
import urls, { externalRoutes } from '../../../routes/all-routes';
import { useLocation, useNavigate } from 'react-router-dom';
import SwitchStoresPane from '../SwitchStoresPane';
import Avatar from '../../../components/avatar';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/setting.svg';
import { ReactComponent as FileQuestionIcon } from '../../../assets/icons/file-question.svg';
import IconButton from '../../../components/common/buttons/icon-button';
import Breadcrumbs, {
  BreadcrumbsProps,
} from '../../../components/common/breadcrumb';
import ExternalLink from '../../../components/common/text/external-link';
import useScreenSizeHook from '../../../hooks/useScreenSizeHook';
import Tooltip from '../../../components/common/tooltip/tooltip';
import Switch from '../../../components/common/switch/switch';
import { match } from 'path-to-regexp';
import { IAppsState } from '../../../store/apps/apps.interface';
import { editAppAction } from '../../../store/apps/apps.actions';
import toast from 'react-hot-toast';
import Spinner from '../../../components/common/spinner/spinner';

export default function Topbar({ breadcrumb }: BreadcrumbsProps) {
  const dispatch = useAppDispatch();
  const [appState, setAppState] = useState(false);
  const [showAppState, setShowAppState] = useState(false);
  const [showAllStores, setShowAllStores] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const sidebarState = useAppSelector((state) => state.layoutReducer);
  const { current_organization_profile, organizations } = useAppSelector(
    (state) => state.account
  );

  const appStoreState: IAppsState = useAppSelector((state) => state.apps);

  console.log(appStoreState);

  useEffect(() => {
    const fn = match('/app/:id');

    setShowAppState(!!fn(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    if (!appStoreState.isSingleAppLoading && appStoreState.focusApp) {
      setAppState(appStoreState.focusApp.state === 'live');
    }
  }, [appStoreState.isSingleAppLoading, appStoreState.focusApp]);

  const onAppStateChange = () => {
    setAppState((prev) => !prev);
    dispatch(
      editAppAction({
        app_id: appStoreState.focusApp.id,
        values: {
          state: appState ? 'test' : 'live',
          name: appStoreState.focusApp.name,
          services: appStoreState.focusApp.services,
        },
      })
    )
      .unwrap()
      .then(() => {
        toast.success(
          `Success! App is now in ${appState ? 'test' : 'live'} mode.`,
          {
            position: 'bottom-center',
          }
        );
      })
      .catch((err) => {
        setAppState((prev) => !prev);
        console.log(err);
      });
  };

  const toggleSwitchStorePane = () => {
    setShowAllStores(!showAllStores);
  };

  const { isDesktop } = useScreenSizeHook();

  return (
    <div className="topbar">
      <div className="logo-container">
        <button
          className="icon"
          onClick={() => {
            if (sidebarState.isSidebarOpen === true) {
              dispatch(CloseSideBar());
            } else {
              dispatch(OpenSideBar());
            }
          }}
        >
          <Menu />
        </button>
      </div>

      <div className="topbar-content">
        {isDesktop && <Breadcrumbs breadcrumb={breadcrumb} />}
        <div className="shortcuts">
          {showAppState &&
          !appStoreState.isSingleAppLoading &&
          appStoreState.focusApp ? (
            <div className="shortcut-group">
              {appStoreState.createAppStatus === 'loading' ? <Spinner /> : ''}
              <Switch
                isChecked={appState}
                setIsChecked={setAppState}
                onChange={onAppStateChange}
                disabled={appStoreState.createAppStatus === 'loading'}
              />
              <div className="app-state-toggle">
                {appState ? (
                  <span className="live">Live Mode</span>
                ) : (
                  <span className="test">Test Mode</span>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="shortcut-group">
            {isDesktop && (
              <Tooltip content="Docs" position="bottom">
                <ExternalLink
                  href={externalRoutes.docs.index}
                  label={
                    <IconButton
                      className="topbar-shortcut-button"
                      icon={<FileQuestionIcon />}
                    />
                  }
                />
              </Tooltip>
            )}

            <Tooltip content="Settings" position="bottom">
              <IconButton
                onClick={() => navigate(urls.settings.account_management.index)}
                icon={<SettingsIcon />}
              />
            </Tooltip>
          </div>

          <div className="shortcut-group">
            <button
              className="user-data"
              onClick={() => {
                if (organizations && organizations?.length > 1) {
                  toggleSwitchStorePane();
                }
              }}
            >
              {current_organization_profile?.name}{' '}
              {organizations && organizations?.length > 1 && <ArrowDown />}
            </button>

            {isDesktop && (
              <button
                className="user-data"
                onClick={() => {
                  navigate(urls.settings.profile.index);
                }}
              >
                <Avatar />
              </button>
            )}
          </div>
        </div>
      </div>

      <SwitchStoresPane
        isOpen={showAllStores}
        onClose={() => {
          toggleSwitchStorePane();
        }}
      />
    </div>
  );
}
